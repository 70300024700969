@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200&display=swap');

* {
    font-family: 'Inter', sans-serif;
    font-style: normal;
}

.MuiInputBase-root {
    /* border-radius: 10px !important; */
}

button.MuiButton-outlined,
button.MuiButton-contained {
    /* border-radius: 10px !important; */
    text-transform: none;
}

.MuiFormControl-root {
    /* margin-left: 0px !important; */
}

.scroll-bar::-webkit-scrollbar {
    -webkit-appearance: none;
}

.scroll-bar::-webkit-scrollbar:vertical {
    width: 11px;
}

.scroll-bar::-webkit-scrollbar:horizontal {
    height: 11px;
}

.scroll-bar::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    /* should match background, can't be transparent */
    background-color: #94A3B8;
}

.btn-radius {
    border-radius: 10px !important;
}

.MuiInputBase-root.Mui-disabled, .MuiInputBase-root.Mui-disabled input, .MuiInputBase-root.Mui-disabled textarea, .MuiSelect-select.Mui-disabled {
    color: #111927;
    -webkit-text-fill-color: #111927;
}

.MuiInputBase-root.Mui-disabled fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.mediaButton {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border: 1px #e0e0e0 solid;
    width:100px;
    height:48px; 
}

.mediaButton:hover {
    cursor: pointer;
}

.selectedMediaType {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    border: 1px #2563ea solid;
    color: #2563ea;
    width:100px;
    height:48px;
    background-color: rgb(233, 239, 253);
}

.selectedMediaType:hover {
    cursor: pointer;
}