@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Kufi+Arabic:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

/* custome style files */
@import './components/Templates/form/form.css';

/* packages style files */
@import 'react-day-picker/dist/style.css';
@import 'react-resizable/css/styles.css';
@import 'react-phone-input-2/lib/style.css';
@import 'react-phone-input-2/lib/material.css';
/* @import '@openchatai/copilot-widget/index.css'; */
@import '@waleed-hesham/react-phone-input-2/lib/style.css';
@import '@waleed-hesham/react-phone-input-2/lib/material.css';
@import 'rc-time-picker/assets/index.css';

@tailwind base;
@tailwind utilities;
@tailwind components;

* {
    font-family: 'Inter', sans-serif !important;
    font-style: normal;
}
[dir="rtl"] * {
    font-family: 'Noto Kufi Arabic', sans-serif !important;
}

/* remove scrollbar */
.hideScrollbar {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

button.MuiButtonBase-root {
    text-transform: none;
}

.hideScrollbar::-webkit-scrollbar {
    display: none;
}

.primary-bg {
    background-color: rgba(37, 99, 235, 0.12);
}

.message-container {
    -webkit-transition: background-color 500ms ease-out;
    -ms-transition: background-color 500ms ease-out;
    transition: background-color 500ms ease-out;
}

.MuiButton-startIcon {
    margin: 0 !important;
    margin-inline-end: 8px !important;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ul, .thin-scroll {
    scrollbar-width: thin;
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ul::-webkit-scrollbar, .thin-scroll::-webkit-scrollbar {
    width: 6px;
	background-color: rgba(17, 25, 39, 0.04);
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.MuiAutocomplete-popper .MuiAutocomplete-paper ul::-webkit-scrollbar-thumb, .thin-scroll::-webkit-scrollbar-thumb {
    width: 6px;
	background-color: #6C737F1A;
    box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
}

.prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

iframe {
    border: none;
}

.MuiInputBase-root.Mui-disabled fieldset {
    border-color: rgba(0, 0, 0, 0.23) !important;
}

.MuiIconButton-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

.MuiButton-outlined.Mui-disabled {
    color: rgba(0, 0, 0, 0.38) !important;
}

.MuiCheckbox-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.3) !important;
}

.MuiRadio-root.Mui-disabled {
    color: rgba(0, 0, 0, 0.3) !important;
}

/* Devias Input */
.MuiTextField-root.devias-textbox input {
    padding: 24px 14px 8px !important;
}

.MuiTextField-root.devias-textbox legend {
    display: none;
}

.MuiTextField-root.devias-textbox label.Mui-focused, .MuiTextField-root.devias-textbox label.MuiFormLabel-filled {
    transform: translate(14px, 6px) scale(0.75);
}

.MuiTextField-root.devias-textbox fieldset {
    top: 0;
    border-radius: 8px;
}

/* Small Version */
.MuiTextField-root.devias-textbox.small input {
    padding: 18px 14px 6px !important;
}

.MuiTextField-root.devias-textbox.small label {
    transform: translate(14px, 12px) scale(1);
}

.MuiTextField-root.devias-textbox.small label.Mui-focused, .MuiTextField-root.devias-textbox.small label.MuiInputLabel-shrink, .MuiTextField-root.devias-textbox.small label.MuiFormLabel-filled, label.devias-small-label[data-shrink="true"] {
    transform: translate(14px, 4px) scale(0.70);
}

.devias-small-label label[data-shrink="false"], label.devias-small-label[data-shrink="false"] {
    transform: translate(14px, 12px) scale(1);
}

/* RTL Style */
[dir="rtl"] .MuiTextField-root.devias-textbox label, [dir="rtl"] .MuiTextField-root.devias-textbox label {
    right: 0;
    left: auto;
    transform-origin: right;
    transform: translate(-16px, 14px) scale(1);
}

[dir="rtl"] .MuiTextField-root.devias-textbox label.Mui-focused, [dir="rtl"] .MuiTextField-root.devias-textbox label.MuiFormLabel-filled {
    right: 0;
    left: auto;
    transform-origin: right;
    transform: translate(-14px, 6px) scale(0.75);
}

[dir="rtl"] .MuiTextField-root.devias-textbox.small label {
    right: 0;
    left: auto;
    transform-origin: right;
    transform: translate(-16px, 10px) scale(1);
}

[dir="rtl"] .MuiTextField-root.devias-textbox.small label.Mui-focused, [dir="rtl"] .MuiTextField-root.devias-textbox.small label.MuiFormLabel-filled, [dir="rtl"] .MuiTextField-root.devias-textbox.small label[data-shrink="true"], [dir="rtl"] label.devias-small-label[data-shrink="true"] {
    right: 0;
    left: auto;
    transform-origin: right;
    transform: translate(-14px, 0px) scale(0.70);
}

[dir="rtl"] .devias-small-label label[data-shrink="false"], [dir="rtl"] label.devias-small-label[data-shrink="false"] {
    right: 0;
    left: auto;
    transform: translate(-14px, 12px) scale(1);
}

[dir="rtl"] .devias-small-label label[data-shrink="true"] {
    right: 0;
    left: auto;
    transform: translate(8px, -9px) scale(0.75);
}

[dir="rtl"] .devias-textbox-filled label.MuiFormLabel-root {
    transform-origin: right;
    left: auto;
    right: 25px;
}

p.MuiFormHelperText-root {
    text-align: start;
}

[dir="rtl"] .MuiChip-deletable svg.MuiChip-deleteIcon {
    margin: 0 -6px 0 5px;
}

/* for phone input */
.react-tel-input .flag-dropdown .selected-flag {
    border-radius: 8px 0 0 8px !important;
}

.react-tel-input .flag-dropdown .selected-flag .flag{
    left: 50%;
    transform: translateX(-50%);
}

.react-tel-input .special-label {
    z-index: 3 !important;
    color: rgba(0, 0, 0, 0.6);
    transform: translate(30px, 10px);
    font-size: 12px !important;
}

[dir="rtl"] .react-tel-input .special-label {
    right: 25px;
    left: auto;
    transform: translate(16px, 10px);
    background: transparent;
    top: -11px;
}

[dir="rtl"] .react-tel-input input {
    text-align: right;
}

[dir="rtl"] .react-tel-input .flag-dropdown .selected-flag .flag .arrow {
    left: 9px;
}

/* for select */
[dir="rtl"] .MuiAutocomplete-root .MuiInputBase-root, [dir="rtl"] .MuiSelect-select.MuiSelect-outlined {
    padding-left: 39px;
    padding-right: 9px !important;
}

[dir="rtl"] .MuiSelect-select.MuiSelect-outlined {
    padding-right: 14px !important;
}

[dir="rtl"] .MuiAutocomplete-root .MuiAutocomplete-endAdornment, [dir="rtl"] .MuiSelect-icon {
    right: auto !important;
    left: 9px;
}

/* for teams popup */
.inputTeamModal input {
    width: 90%;
}

[dir="ltr"] .country {
    padding: 7px 40px !important;
}

/* Tooltip */
.MuiTooltip-popper {
    z-index: 999999 !important;
}

.MuiFormHelperText-root.Mui-error {
    margin-left: 12px;
    margin-right: 12px;
}

/* Date Range RTL */
[dir="rtl"] .rdp-nav_button_previous, [dir="rtl"] .rdp-nav_button_next {
    scale: -1;
}

/* Stepper RTL */
.MuiStepLabel-iconContainer {
    padding-right: 0 !important;
    padding-inline-end: 8px !important;
}

.MuiStepContent-root {
    margin-left: 0 !important;
    margin-inline-start: 12px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-inline-start: 20px !important;
    padding-inline-end: 8px !important;
    border-left: none !important;
    border-inline-start: 1px solid #bdbdbd !important;
}

.MuiStepConnector-line.MuiStepConnector-lineVertical {
    border-left: none;
    border-inline-start-width: 1px !important;
    border-inline-start-style: solid !important;
    border-inline-start-color: #bdbdbd;
}

[dir="rtl"] .rc-dialog-body div:first-child {
    right: -1rem;
    left: 1rem;
}

/* Monaco Editor */
[class^="codicon-"], [class*="codicon-"] {
    font-family: "codicon" !important;
}

.infinite-scroll-component__outerdiv {
    height: 100%;
}

/* Select Dropdown Style */
#menu- .MuiPaper-root {
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.08) !important;
    border-radius: 8px !important;
}

#menu- .MuiPaper-root ul li {
    margin: 4px 8px;
}

#menu- .MuiPaper-root ul li.Mui-selected {
    background-color: #F5F6F6;
    border-radius: 10px;
}

#menu- .MuiPaper-root ul li:hover {
    background-color: #F5F6F6;
    border-radius: 10px;
}

/* Room Styles */
.roombg {
    background-color: rgb(234, 243, 254);
}

.PIP_pipWindow__bvNPJ {
    background-color: rgb(234, 243, 254) !important;
}

.react-resizable div {
    background-color: rgb(234, 243, 254) !important;
}

@keyframes fadeInOut {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.fade-in-out-animation {
    width: 100px;
    height: 100px;
    background-color: #3498db;
    opacity: 0;
    transition: opacity 0.5s ease;
    animation: fadeInOut 2s infinite; /* Change the duration as needed */
}

.inverted {
    filter: invert(1);
}
  
.inverted img {
    filter: invert(1); /* This will re-invert the image, effectively cancelling the inversion */
}